
import styled from 'styled-components';
const HeadingMain = styled.div`
  width: 100%;
  background: linear-gradient(rgb(82, 168, 237) 0%, rgb(138, 211, 247) 100%);
  display: flex;
  border-radius: 7px;
  >div:first-child{
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 6px;
    >img{
       width: 32px;
       height: 32px;
       margin: auto;
       display: flex
    }
   }
   >div:last-child{
    width: calc(100% - 50px);
    display: flex;
    margin: auto auto auto 10px;
    >span{
        text-transform: capitalize;
        font-family: Rubik-Medium;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: white;
    }
   }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  max-width: 1246px;
  margin: auto;
  flex-wrap: wrap;
`;

const VideoContainerNew = styled.div`
  width: 100%;
  float: left;
  margin: 0 auto;
  > div {
    width: 100% !important;
  }
  .react-player {
    height: 440px !important;
    background: #005c87;
    border-radius:6px;
    @media (max-width: 500px) {
      height: 200px !important;
    }

    @media (max-width: 1490px) {
      height: 439px !important;
    }
    @media (max-width: 1465px) {
      height: 440px !important;
    }
    @media (max-width: 1435px) {
      height: 430px !important;
    }
    @media (max-width: 1410px) {
      height: 420px !important;
    }
    @media (max-width: 1385px) {
      height: 410px !important;
    }
    @media (max-width: 1360px) {
      height: 400px !important;
    }
    @media (max-width: 1335px) {
      height: 390px !important;
    }
    @media (max-width: 1305px) {
      height: 380px !important;
    }
    @media (max-width: 1280px) {
      height: 370px !important;
    }
 
    >div{
      border-radius: 6px 6px 0 0;
    }
  }

  .react-player-webinar {
    height: 400px !important;
    @media (max-width: 500px) {
      height: 200px !important;
    }
  }
`;

const ImageContainerIcons = styled.div`
  width: 100%;
  margin-bottom: 25px;
  margin-bottom: ${({ margin }) => margin ? '0px' : '25px'};
  display: flex;
  padding: ${({ padding }) => padding ? '15px' : '0'};
  background: white;
  border-radius: 0 0 6px 6px;
  height: 50px;
  border-bottom: 1px solid rgba(0, 92, 135, 0.10);
  >div:first-child{
    width: 50%;
    display: flex;
    >div:first-child{
      display: flex;
      justify-content: flex-start;
      margin-right: 20px;
      >img{
        height: 24px;
        width: 24px;
        margin: auto;
      }
      >span{
        font-size: 16px;
        font-family: rubik;
        margin: auto;
        margin-left: 10px;
        text-align: center;
        font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 12px;
color: #005C87;
      }
    }    
    >div:nth-child(2){
      display: flex;
      margin-right: 20px;
      >img{
        height: 24px;
        width: 24px;
        margin: auto;
      }
      >span{
        font-size: 16px;
        font-family: rubik;
        text-align: center;
        margin: auto;
        margin-left: 15px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 12px;
        color: #005C87;

      }
    }
    >div:last-child{
      display: flex;
      >img{
        height: 24px;
        width: 24px;
        margin: auto;
      }
      >span{
        font-size: 14px;
        font-family: rubik;
        text-align: center;
        margin: auto;
        margin-left: 10px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 12px;
        color: #005C87;

      }
    }

  }
  > div: last-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    display: flex;
    margin: auto;
  }
`;

const CircleContainerNew = styled.div`
  display: flex;
  margin-right: ${({marginRight}) => marginRight ? '0px': '0px'};
  @media (max-width: 660px){
    margin-right: 0px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }
  .quiz{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-size: ${({font}) => font ? '14px': '12px'};
    line-height: 12px;
    color: #3a3a3a;
    color: ${({color}) => color ? '#02293D': '#3a3a3a'};
    margin: auto;
    margin-left: 10px;
  }
`;

const Circle = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 16px;
  border: ${({checked }) => checked  ? '3px solid #96C26E':'1px solid #005c87'};
  border-color: ${({ checked }) => checked ? '#96C26E' : '#005c87'};
`;

const Tick = styled.div`
  content: "";
  position: relative;
  display: block;
  left: 9px;
  top: 5px;
  width: 6px;
  height: 12px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: ${({ checked }) => checked ? '#96C26E' : 'white'};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
`;

const FavoriteContainer = styled.div`
width: 100%;
display: flex;
>div:first-child{
 width: 80%;
 text-transform: capitalize;
 font-family: Rubik-Medium;
 font-weight: 500;
 font-size: 20px;
 color: #0D4270;
 padding-bottom: 15px;
 padding-top: 0px;
}
>div:last-child{
  width: 20%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  >img{
    width: 20px;
    height: 30px;
  }
}
`;

const VideoContainer = styled.div`
  width: 100%;
  margin-bottom:${({margin, marginBottom }) => marginBottom?marginBottom: margin? "25px" : '15px'};
  padding:${({padding }) => padding? padding : ''};
  background:${({background }) => background? "white" : ''};
  border-radius: 6px;

  .pointValue{
    width:100%;
    background:white;
    display:flex;
    justify-content:space-between;
    padding:15px;
    border-radius:6px;
    margin-top:15px;
    .text{
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color:#005C87;      
    }
    .point{
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color:#005C87;  
    }
  }
`;

const EventDateContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 80px;
  cursor:${({cursor }) => cursor? 'pointer' : ''};
  > div: first-child {
    width: 10%;
    display: flex;
    >img{
      width: auto;
      height: 60px;
      margin: auto;
      margin: 10px;
    }
  }
  > div: last-child {
    width: 90%;
    display: block;
    > div: first-child {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      justify-content: space-between;
      > div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #9c9c9c;
      }
      .attendies{
        cursor: pointer;
      }
      > div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({color }) => color? '#69c2ff' : '#69c2ff'};
        text-transform: capitalize;

      }
    }
    > div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 10px;
      > div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #9c9c9c;

      }
  
      > div: last-child {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({color }) => color? '#69c2ff' : '#69c2ff'};
 
      }
      .locationPlace{
        color: #0D4270;
      }
    }
  }
`;

const EventDateContainerV2 = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  cursor:${({ cursor }) => cursor ? 'pointer' : ''};
  > div: first-child {
    width: 9.8%;
    max-width: 75px;
    margin:auto;
    margin:${({ margin }) => margin ? margin : '17px 0px 15px 15px'};
    display: flex;
    >img{
      width: auto;
      height: 60px;
      margin: auto;
      margin:${({ margin }) => margin ? margin : '10px 10px 10px 10px'};
    }
  }
  > div: last-child {
    width: 90.2%;
    display: block;
    margin: auto;
    margin-right: 5px;
    padding:15px;
    > div: first-child {
      width: 100%;
      display: flex;
      padding:${({ paddingTop }) => paddingTop ? paddingTop : '0px 0px 10px 0px'};
      justify-content: space-between;
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #005c87;
        line-height:20px;
      }
      .attendies{
        cursor: pointer;
      }
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({color }) => color? color : 'white'};

      }
    }
    > div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding:${({ paddingBottom }) => paddingBottom ? paddingBottom : '0px 0px 10px 0px'};
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #005c87;
        line-height:20px;

      }
  
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({ color }) => color ? color : 'white'};
 
      }
      .locationPlace{
        color: #0D4270;
      }
    }
  }
`;

const PointValue = styled.div`
  width: 100%;
  display: flex;
  background: white;
  margin-bottom: 15px;
  border-radius: 6px;
  height:50px;
  > div: first-child {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-family: rubik;
    color: ${({ color }) => color ? color : '#0D4270'};
    padding: 0px 0 0 15px;
    align-items: center;
  }
   > div: last-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-family: rubik-medium;
    color: #0D4270;
    padding: 0px 15px 0 0px;
    align-items: center;
  }
`;

const AboutContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 6px;
  margin: ${({ marginTop }) => marginTop ? marginTop : '10px 0px 0px 0px'};
  > div: first-child {
     font-size: 18px;
     font-family: rubik-medium;
     padding: 15px;
     color: #0D4270;
     display: flex;
     line-height: 24px;
  }
  > div: last-child {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: ${({ color }) => color ? color : '#3A3A3A'};
    display: flex;
    flex-wrap: wrap;
    padding: 0px 25px 25px 15px;
    >p{
      margin:0px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: ${({ color }) => color ? color : '#3A3A3A'};
      text-align: justify;
    }
  }
`;

const OndemandRightSection = styled.div`
width:100%;
display:block;
.ondemand-button{
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 92, 135, 0.10);
  color: #005C87;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #005C87;
  margin-bottom: 28px;
}
.ondemand-details{
  display:flex;
  width:100%;
  padding:20px 0;
  border-top: 1px solid rgba(0, 92, 135, 0.10);
  .image{
    width:24px;
    height:24px;
    margin-right:20px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .name{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    align-items: center;
    margin: auto auto 0 0;
    display: flex;
  }
}
`;

export {HeadingMain, Container, VideoContainerNew, CircleContainerNew, Tick, Circle, ImageContainerIcons, FavoriteContainer, VideoContainer, EventDateContainer, EventDateContainerV2, PointValue, AboutContainer, OndemandRightSection};