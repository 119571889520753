/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {LayoutNew} from '../../EventDetails/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Waiting from '../../Waiting';
import OnDemandVideoDetail from './ondemandDetail';

class OnDemandVideoDetailMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      buttonStatus: 0
    }
  }

  componentDidMount() {
    const { history: { location: { pathname } },fetchOndemandEventDetailsById } = this.props;
    const StringArray = pathname.split('/');

    if (StringArray[(StringArray.length - 1)] !== '') {
      fetchOndemandEventDetailsById(StringArray[(StringArray.length - 1)]);
    } else {
      fetchOndemandEventDetailsById(StringArray[(StringArray.length - 2)]);
    }
  }

  render() {
    const {ondemandEventDetail, fetchOndemandEventDetailsById, allowedRoles, isAdmin, role } = this.props;
    if (isUndefined(ondemandEventDetail) || isNull(ondemandEventDetail) ) {
      return <Waiting />
    }
    return (
      <LayoutNew>
        <OnDemandVideoDetail
          eventDetail={ondemandEventDetail}
          fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
          permission={allowedRoles}
          isAdmin={isAdmin}
          role={role}
        />
      </LayoutNew>
    )
  }
}

OnDemandVideoDetailMain.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  fetchEventDetailsById: PropTypes.func.isRequired,
  location: PropTypes.object,
  eventDetail: PropTypes.object,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  ondemandEventDetail: PropTypes.object,
  allowedRoles: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  role: PropTypes.string
};

const mapStateToProps = (state) => ({
  eventDetail: state.events.eventDetail,
  ondemandEventDetail: state.events.ondemandEventDetail,
});

export default connect(mapStateToProps, null)(withTranslation()(OnDemandVideoDetailMain));